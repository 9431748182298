import React from 'react';

import SocialMediaButton from '../components/buttons/social-media';

import logo from '../assets/images/logo-white.svg';
import './styles.scss';

export default function Home() {
  return (
    <div className='homepage'>
      <img className='logo' src={logo} alt='CrustByte' title='CrustByte' />

      <div className='info'>
        <h1>The crust is not ready yet. We are still baking the code.</h1>
        <h3>In the meantime, you can follow us on:</h3>

        <ul className='social-media-list'>
          <li><SocialMediaButton type='facebook' link='https://www.facebook.com/CrustByte' /></li>
          <li><SocialMediaButton type='instagram' link='https://www.instagram.com/crustbyte' /></li>
        </ul>
      </div>
    </div>
  );
}
