import React from 'react';
import facebook from '../../../assets/icons/social-media/facebook.svg'
import instagram from '../../../assets/icons/social-media/instagram.svg'

import './styles.scss'

const SocialMediaButton = ({ type, link }) => {
  const getImage = () => {
    switch (type) {
      case 'facebook':
        return facebook;
      case 'instagram':
        return instagram;
      default:
        return facebook;
    }
  };

  return (
    <a className='social-btn' href={link} target="_blank" rel="noreferrer">
      <img className='social-icon' src={getImage()} alt={type}/>
    </a>
  );
};

export default SocialMediaButton;
